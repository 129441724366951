import "./shop.css";

import {
  AboutCard,
  AboutColumn,
  AboutHolder,
  AboutRow,
  AboutSec,
  BtnWrapper,
  MainBtnLink,
  MainTitle,
  NavBtnLink,
  Para,
  SvgWrapper2,
  TextWrapper,
  TopLine,
} from "./Shop.elements";
import { Button, Container } from "../../globalStyles";
import React, { useState } from "react";
import {
  marketing,
  payment,
  productManagment,
  shipping,
  shopInfo,
  storeBuilder,
} from "../HomePage/Data";

import { InfoSection } from "../../components";

import messi from "../../images/messiFull.PNG";

// import { Link } from 'react-router-dom';

const Shop = ({
  lightBg,

}) => {
  const [value, setValue] = useState("Hello World");
  const handleChange = (e) => setValue(e.target.value);
  //const { imgStart, } = shopInfo
  const [businessType, setBusinessType] = useState("");
  const [storeName, setStoreName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Remove "a" or "an" from business type and clean up the string
    const cleanBusinessType = businessType.replace(/^(a|an)\s+/, '').trim();
    // Create the URL with parameters - using the correct format
    const redirectUrl = `https://vcboard.qrfds.com/register?buildtype=1&businesstype=${encodeURIComponent(cleanBusinessType)}&businessname=${encodeURIComponent(storeName)}`;
    // Log the URL to verify it's correct (for debugging)
    console.log('Redirecting to:', redirectUrl);
    // Redirect to the URL
    window.location.href = redirectUrl;
  };

  return (
    <>
      <div id="shop"></div>

      <AboutSec lightBg={!lightBg} data-aos="fade-up" data-aos-duration="400">
        <Container>
          <MainTitle>
            <span
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
             Idea to store in seconds
            </span>
          </MainTitle>
          {/* <Para data-aos="fade-up" data-aos-duration="3000">
            Ready to start building your online store? Sign up for our ecommerce
            builder today and get started in minutes. With our easy-to-use tools
            and customizable templates, you'll have a beautiful,
            professional-looking online store up and running in no time.
          </Para> */}
          <form className="chat-input-form" onSubmit={handleSubmit}>
            <div className="chat-input-wrapper">
              <textarea 
                className="chat-input"
                id="chatinput"
                value={`I want to build ${businessType || '[select business type]'} online store called ${storeName || '[enter store name]'}`}
                rows="3"
                disabled
              />
              <div className="inline-inputs">
                <span className="static-text">I want to build</span>
                <select 
                  className="inline-select"
                  value={businessType}
                  onChange={(e) => setBusinessType(e.target.value)}
                  style={{ width: businessType ? `${businessType.length + 3}ch` : '15ch' }}
                >
                  <option value="">select type</option>
                  <option value="a clothing">a clothing</option>
                  <option value="an electronics">an electronics</option>
                  <option value="a food">a food</option>
                  <option value="a beauty">a beauty</option>
                  <option value="a home">a home</option>
                  <option value="a sports">a sports</option>
                  <option value="a toys">a toys</option>
                  <option value="default">a default</option>
                </select>
                <span className="static-text">online store called</span>
                <input 
                  type="text"
                  className="inline-input"
                  placeholder="enter name"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
              </div>
            </div>
            <div className="input-controls">
              <button 
                type="submit" 
                className="generate-button" 
                disabled={!businessType || !storeName}
              >
                Generate
              </button>
            </div>
          </form>
          <MainTitle>
            {/* <SvgWrapper2>
              <a
                href="https://vcboard.qrfds.com/"
                alt="Mythrill"
                target="_blank"
              >
                <div class="card">
                  <div class="wrapper">
                    <img
                      src="https://shop.app/cdn/shopifycloud/arrive_website/assets/marketing/gravity/home-cash-iphone-small-bf375941a47aa2b79ceff827082e2be6c70d8c6fdf3f7ba662ec70e43aa4c9db.png"
                      class="cover-image"
                    />
                  </div>
               
                  <img src={messi} class="character" />
                </div>
              </a>
            </SvgWrapper2> */}
          </MainTitle>


          <InfoSection {...storeBuilder} />
          <InfoSection {...marketing} />
          <InfoSection {...productManagment} />
          <InfoSection {...payment} />
          <InfoSection {...shipping} />
          {/* </AboutRow> */}
        </Container>
      </AboutSec>
    </>
  );
};

export default Shop;
